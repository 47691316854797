import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/parts',
    name: 'SettingsParts',
    component: () =>
      import(
        /* webpackChunkName: "SettingsParts" */ '@/pages/settings/part/index/PartIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'parts',
      breadCrumb: [{ text: 'settings' }, { text: 'parts' }]
    }
  },
  {
    path: '/settings/part/groups',
    name: 'SettingsPartGroups',
    component: () =>
      import(
        /* webpackChunkName: "SettingsPartGroups" */ '@/pages/settings/part/group/PartGroupIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'partGroups',
      breadCrumb: [
        { text: 'settings' },
        { text: 'parts' },
        { text: 'partGroups' }
      ]
    }
  }
]
