import VueJwtDecode from 'vue-jwt-decode'

const ID_TOKEN_KEY = 'id_token'

export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY)

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export const getUser = () => {
  try {
    const tokenData = VueJwtDecode.decode(
      window.localStorage.getItem(ID_TOKEN_KEY)
    )
    if (tokenData) {
      return { email: tokenData.email }
    }
  } catch (e) {}
  return undefined
}

export default { getToken, saveToken, destroyToken }
