import can from '@/router/middleware/can'

import auth from '../../middleware/auth'

export default [
  {
    path: '/contracts',
    name: 'Contracts',
    component: () =>
      import(
        /* webpackChunkName: "contracts" */ '@/pages/contracts/ContractIndex'
      ),
    meta: {
      middleware: [can('isContractShow'), auth],
      title: 'contracts',
      breadCrumb: [{ text: 'contracts' }]
    }
  },
  {
    path: '/contract/:contractId',
    name: 'ContractShow',
    component: () =>
      import(
        /* webpackChunkName: "contractShow" */ '@/pages/contracts/ContractShow'
      ),
    props: true,
    meta: {
      middleware: [can('isContractShow'), auth],
      title: 'contracts',
      breadCrumb: [
        { text: 'contracts', to: { name: 'Contracts' } },
        { text: undefined }
      ]
    }
  }
]
