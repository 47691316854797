/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import { findIndex, keyBy, reject } from 'lodash'
import Vue from 'vue'

import { FETCH_STAFFS } from '../../actions.type'
import {
  CREATE_STAFF,
  REMOVE_STAFF,
  SET_STAFF_ALL_REQUEST,
  SET_STAFF_ALL_VALUE,
  UPDATE_STAFF
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  staffAllValue: (state) => state.all.value,
  staffAllRequest: (state) => state.all.request,
  staffAllById: (state) => keyBy(state.all.value, 'id')
}

const actions = {
  async [FETCH_STAFFS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('staff', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_STAFF_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_STAFF_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_STAFF_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_STAFF_ALL_VALUE, data)
    } catch (e) {
      commit(SET_STAFF_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_STAFF({ commit }, value) {
    commit(CREATE_STAFF, value)
  },

  async SOCKET_UPDATE_STAFF({ commit }, value) {
    commit(UPDATE_STAFF, value)
  },

  async SOCKET_REMOVE_STAFF({ commit }, id) {
    commit(REMOVE_STAFF, id)
  }
}

const mutations = {
  [SET_STAFF_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_STAFF_ALL_VALUE](state, staffs) {
    state.all.value = staffs
  },
  [UPDATE_STAFF](state, updatedStaff) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedStaff.id }),
      1,
      updatedStaff
    )
  },
  [CREATE_STAFF](state, createdStaff) {
    state.all.value.push(createdStaff)
  },
  [REMOVE_STAFF](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
