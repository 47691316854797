import can from '@/router/middleware/can'

import auth from '../../middleware/auth'

export default [
  {
    path: '/clients',
    name: 'Clients',
    component: () =>
      import(/* webpackChunkName: "clients" */ '@/pages/clients/ClientIndex'),
    meta: {
      middleware: [can('isClientShow'), auth],
      title: 'clients',
      breadCrumb: [{ text: 'clients' }]
    }
  },
  {
    path: '/client/:clientId',
    name: 'ClientShow',
    component: () =>
      import(/* webpackChunkName: "clientShow" */ '@/pages/clients/ClientShow'),
    props: true,
    meta: {
      middleware: [can('isClientShow'), auth],
      title: 'clients',
      breadCrumb: [
        { text: 'clients', to: { name: 'Clients' } },
        { text: undefined }
      ]
    }
  }
]
