import guest from '../../middleware/guest'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "LoginForm" */ '@/pages/auth/LoginView'),
    meta: {
      middleware: [guest]
    }
  }
]
