<template>
  <v-dialog
    :value="
      loaderModalValue.isLoading ||
      loaderModalValue.isError ||
      loaderModalValue.isFatalError
    "
    hide-overlay
    persistent
    width="400"
  >
    <v-alert :type="alertType" class="ma-0">
      <template v-if="loaderModalValue.isFatalError">
        {{ $t('errorLoadingFromServer') }}
      </template>
      <template v-else-if="loaderModalValue.isError">
        {{ $t('errorLoadingFromServer') }}
        <span class="text-lowercase"> , {{ $t('nextTry') }}... </span>
      </template>
      <template v-else> {{ $t('loading') }}... </template>
      <v-progress-linear
        v-if="!loaderModalValue.isFatalError"
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-alert>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoaderModal',
  computed: {
    alertType() {
      return this.loaderModalValue.isFatalError
        ? 'error'
        : this.loaderModalValue.isError
          ? 'warning'
          : 'info'
    },
    ...mapGetters(['loaderModalValue'])
  }
}
</script>
