<template>
  <v-navigation-drawer
    temporary
    v-model="drawer"
    app
    class="pa-0"
    :width="'100%'"
  >
    <v-list dense>
      <v-list-item>
        <v-list-item-avatar size="30px" class="mb-3">
          <v-img src="@/assets/favicon.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>
          <div class="d-flex">
            <v-btn icon @click="handleLogout">
              <v-icon v-text="'mdi-logout'" />
            </v-btn>
            <div style="display: flex; flex-flow: column">
              <div>
                {{
                  currentUser
                    ? `${
                        isGlobalLocale && currentUser.lastNameI18n
                          ? currentUser.lastNameI18n
                          : currentUser.lastName
                      } ${
                        isGlobalLocale && currentUser.firstNameI18n
                          ? currentUser.firstNameI18n
                          : currentUser.firstName
                      }`
                    : ''
                }}
              </div>
              <div>
                {{
                  currentUser
                    ? isGlobalLocale && currentUser.role.titleI18n
                      ? currentUser.role.titleI18n
                      : currentUser.role.title
                    : ''
                }}
              </div>
            </div>
          </div>
        </v-list-item-title>

        <v-btn icon @click="drawer = false">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <nav-drawer-list />

    <app-footer v-if="$vuetify.breakpoint.xs" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

import { PURGE_AUTH } from '@/store/mutations.type'

import AppFooter from '../AppFooter.vue'
import NavDrawerList from './NavDrawerList.vue'

export default {
  components: { AppFooter, NavDrawerList },
  name: 'NavDrawer',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      drawer: false
    }
  },
  watch: {
    value() {
      this.drawer = this.value
    },
    drawer: {
      immediate: true,
      handler() {
        this.$emit('input', this.drawer)
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'isGlobalLocale'])
  },
  methods: {
    handleLogout() {
      this.$store.commit(PURGE_AUTH)
      this.$router.push({
        name: 'Login'
      })
    }
  }
}
</script>
