import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/placement/countries',
    name: 'SettingsLocaleCountries',
    component: () =>
      import(
        /* webpackChunkName: "countries" */ '@/pages/settings/placement/country/CountryIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'countries',
      breadCrumb: [
        { text: 'settings' },
        { text: 'placement' },
        { text: 'countries' }
      ]
    }
  },
  {
    path: '/settings/placement/regions',
    name: 'SettingsLocaleRegions',
    component: () =>
      import(
        /* webpackChunkName: "regions" */ '@/pages/settings/placement/region/RegionIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'regions',
      breadCrumb: [
        { text: 'settings' },
        { text: 'placement' },
        { text: 'regions' }
      ]
    }
  },
  {
    path: '/settings/placement/cities',
    name: 'SettingsLocaleCities',
    component: () =>
      import(
        /* webpackChunkName: "cities" */ '@/pages/settings/placement/city/CityIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'cities',
      breadCrumb: [
        { text: 'settings' },
        { text: 'placement' },
        { text: 'cities' }
      ]
    }
  }
]
