/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import moment from 'moment-timezone'

import { ADD_NOTIFICATION, READ_ALL_NOTIFICATION } from '@/store/mutations.type'

const state = {
  notifications: []
}

const getters = {
  notificationAllValue: (state) => state.notifications,
  notificationLast: (state) => state.notifications.find((val) => val.isNew),
  notificationCount: (state) => state.notifications.length,
  notificationNewCount: (state) =>
    state.notifications.filter((val) => val.isNew).length
}

const mutations = {
  [ADD_NOTIFICATION](state, val) {
    state.notifications.unshift({
      isNew: true,
      timestamp: moment().unix(),
      ...val
    })
    // setTimeout(() => {
    //   // state.notifications[0].isNew = false
    // }, 7000)
  },
  [READ_ALL_NOTIFICATION](state) {
    state.notifications = state.notifications.map((val) => ({
      ...val,
      isNew: false
    }))
  }
}

export default {
  state,
  getters,
  mutations
}
