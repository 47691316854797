export const CHANGE_ROUTE = 'changeRoute'

export const AUTHENTICATE = 'authenticate'
export const CHECK_AUTH = 'checkAuth'

export const FETCH_LOCALES = 'fetchLocales'

export const FETCH_BRANDS = 'fetchBrands'

export const FETCH_EQ_TYPES = 'fetchEqTypes'
export const FETCH_MODELS = 'fetchModels'

export const FETCH_ORDER_STATUSES = 'fetchOrderStatuses'
export const FETCH_ORDER_TYPES = 'fetchOrderTypes'

export const FETCH_CLIENT_FAULTS = 'fetchClientFaults'
export const FETCH_CLIENT_FAULT_GROUPS = 'fetchClientFaultGroups'

export const FETCH_IDENT_FAULTS = 'fetchIdentFaults'
export const FETCH_IDENT_FAULT_GROUPS = 'fetchIdentFaultGroups'

export const FETCH_CAUSE_FAULTS = 'fetchCauseFaults'
export const FETCH_CAUSE_FAULT_GROUPS = 'fetchCauseFaultGroups'

export const FETCH_WORKS = 'fetchWorks'
export const FETCH_WORK_GROUPS = 'fetchWorkGroups'

export const FETCH_PARTS = 'fetchParts'
export const FETCH_PART_GROUPS = 'fetchPartGroups'

export const FETCH_CLIENTS = 'fetchClients'

export const FETCH_CONTRACTS = 'fetchContracts'

export const FETCH_LOCATIONS = 'fetchLocations'

export const FETCH_COUNTRIES = 'fetchCountries'

export const FETCH_REGIONS = 'fetchRegions'

export const FETCH_CITIES = 'fetchCities'

export const FETCH_EQUIPMENTS = 'fetchEquipments'

export const FETCH_STAFFS = 'fetchStaffs'

export const FETCH_ROLES = 'fetchRoles'

export const FETCH_ZONES = 'fetchZones'

export const FETCH_ORDER = 'fetchOrder'
export const FETCH_ORDERS = 'fetchOrders'

export const FETCH_GLOBAL_SETTINGS = 'fetchGlobalSettings'
