import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/works',
    name: 'SettingsWorks',
    component: () =>
      import(
        /* webpackChunkName: "SettingsWorks" */ '@/pages/settings/work/index/WorkIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'works',
      breadCrumb: [{ text: 'settings' }, { text: 'works' }]
    }
  },
  {
    path: '/settings/work/groups',
    name: 'SettingsWorkGroups',
    component: () =>
      import(
        /* webpackChunkName: "SettingsWorkGroups" */ '@/pages/settings/work/group/WorkGroupIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'workGroups',
      breadCrumb: [
        { text: 'settings' },
        { text: 'works' },
        { text: 'workGroups' }
      ]
    }
  }
]
