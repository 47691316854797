<template>
  <v-system-bar
    app
    :class="!!notificationLast ? 'white--text' : ''"
    :color="$vuetify.theme.dark ? '#272727' : '#f5f5f5'"
    :style="{ background: !!notificationLast ? notificationLast.color : '' }"
    :dark="!!notificationLast && notificationLast.dark"
  >
    <span class="text-truncate" @click="showDialog">
      <v-icon v-text="'mdi-bell'" :color="!!notificationLast ? 'white' : ''" />
      <span>{{ notificationNewCount }} / {{ notificationCount }}</span>
      <span v-if="!!notificationLast" class="ml-2">
        {{ notificationLast.text }}</span
      >
    </span>
    <v-spacer></v-spacer>
    <span v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">{{
      $moment.unix(timestampNow).format('HH:mm DD MMM YYYY G[M]TZ')
    }}</span>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="text-capitalize">
          {{ $t('notifications') }}
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(item, i) in notificationAllValue"
                  :key="i"
                  class="white--text"
                  :style="{ background: item.color }"
                  style="width: 10rem"
                >
                  <td>
                    {{
                      $moment.unix(item.timestamp).format('HH:mm:ss DD-MM-YY')
                    }}
                  </td>
                  <td>{{ item.text }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-system-bar>
</template>

<script>
import { mapGetters } from 'vuex'

import { READ_ALL_NOTIFICATION } from '@/store/mutations.type'

export default {
  name: 'TopBar',
  data() {
    return {
      dialog: false
    }
  },

  computed: {
    ...mapGetters([
      'timestampNow',
      'notificationAllValue',
      'notificationLast',
      'notificationCount',
      'notificationNewCount'
    ])
  },

  methods: {
    showDialog() {
      this.$store.commit(READ_ALL_NOTIFICATION)
      this.dialog = true
    }
  }
}
</script>
