import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/administration/role',
    name: 'SettingsRoles',
    component: () =>
      import(
        /* webpackChunkName: "SettingsRole" */ '@/pages/settings/administration/role/RoleIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'roles',
      breadCrumb: [
        { text: 'settings' },
        { text: 'administration' },
        { text: 'roles' }
      ]
    }
  }
]
