import auth from '../../middleware/auth'
import can from '../../middleware/can'

export default [
  {
    path: '/orders',
    name: 'Orders',
    component: () =>
      import(/* webpackChunkName: "orders" */ '@/pages/orders/OrderIndex'),
    meta: {
      middleware: [can('isOrderShow'), auth],
      title: 'orders',
      breadCrumb: [{ text: 'orders' }]
    }
  },
  {
    path: '/order/:orderId',
    name: 'OrderShow',
    component: () =>
      import(/* webpackChunkName: "orderShow" */ '@/pages/orders/OrderShow'),
    props: true,
    meta: {
      middleware: [can('isOrderShow'), auth],
      title: 'orders',
      breadCrumb: [
        { text: 'orders', to: { name: 'Orders' } },
        { text: undefined }
      ]
    }
  }
]
