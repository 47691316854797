export default function (can) {
  return function ({ next, store }) {
    if (!store.state.auth.staff || !store.state.auth.staff.role[can]) {
      return next({
        name: 'Error',
        params: { errorCode: 403 }
      })
    }
    return next()
  }
}
