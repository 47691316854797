import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/equipment/brand',
    name: 'SettingsEquipmentBrand',
    component: () =>
      import(
        /* webpackChunkName: "SettingsEquipmentBrand" */ '@/pages/settings/equipment/brand/BrandIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'brands',
      breadCrumb: [
        { text: 'settings' },
        { text: 'equipment' },
        { text: 'brands' }
      ]
    }
  },
  {
    path: '/settings/equipment/type',
    name: 'SettingsEquipmentType',
    component: () =>
      import(
        /* webpackChunkName: "SettingsEquipmentType" */ '@/pages/settings/equipment/eq-type/EqTypeIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'eqTypes',
      breadCrumb: [
        { text: 'settings' },
        { text: 'equipment' },
        { text: 'types' }
      ]
    }
  },
  {
    path: '/settings/equipment/model',
    name: 'SettingsEquipmentModel',
    component: () =>
      import(
        /* webpackChunkName: "SettingsEquipmentModel" */ '@/pages/settings/equipment/model/ModelIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'models',
      breadCrumb: [
        { text: 'settings' },
        { text: 'equipment' },
        { text: 'models' }
      ]
    }
  }
]
