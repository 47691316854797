import uk from 'vuetify/lib/locale/uk'

export default {
  account: 'аккаунт',
  accountNotActive: 'обліковий запис вимкнено',
  accounts: 'аккаунти',
  active: 'активний',
  add: 'додати',
  addAccount: 'додати аккаунт',
  address: 'адрес',
  addSelected: 'додати вибране',
  additionalLanguage: 'додаткова мова',
  administration: 'адміністрування',
  all: 'всі',
  apply: 'застосувати',
  allWeek: 'весь тиждень',
  assignedTo: 'тільки призначені',
  back: 'назад',
  backToLoginPage: 'повернутися на сторінку входу',
  beverage: 'напій',
  beverages: 'напої',
  brand: 'бренд',
  brands: 'бренди',
  businessHours: 'години роботи',
  byDays: 'по днях',
  cancel: 'скасувати',
  cancelDelete: 'скасувати видалення?',
  cantDeleteFirstDeleteRelations:
    'видалення заборонено, спочатку видаліть пов’язані об’єкти',
  cantDeleteDefaultValue: 'неможливо видалити значення за замовчуванням',
  cause: 'причина',
  causeFaultGroup: 'група причин несправностей',
  causeFaultGroups: 'групи причин несправностей',
  causeFaults: 'причини несправностей',
  causeGroups: 'групи причин',
  causes: 'причини',
  changePassword: 'змінити пароль',
  cities: 'міста',
  city: 'місто',
  client: 'контрагент',
  clientFault: 'причина виклику',
  clientFaultGroup: 'група причини виклику',
  clientFaultGroups: 'групи причин виклику',
  clientFaults: 'причини виклику',
  clients: 'контрагенти',
  clientUsers: 'користувачі клієнта',
  close: 'закрити',
  closed: 'зачинено',
  code: 'код',
  color: 'колір',
  comment: 'коментар',
  completedDate: 'дата завершення',
  connectionLost: 'з`єднання втрачено',
  connectionRestored: 'з`єднання відновлено',
  confirmDelete: 'ви впевнені, що хочете видалити цей елемент?',
  confirmPassword: 'підтвердьте пароль',
  connectServerError: 'помилка підключення до сервера',
  contactPerson: 'контактна особа',
  contactPersons: 'контактні особи',
  contract: 'договір',
  contractor: 'виконавець',
  contracts: 'договори',
  controlTechConditions: 'контроль техн. умов',
  counter: 'лічильник',
  counterFrom: 'лічильник від',
  counterTo: 'лічильник до',
  countries: 'країни',
  country: 'країна',
  create: 'створити',
  createdDate: 'дата створення',
  date: 'дата',
  dateEnd: 'дата закінчення',
  dateStart: 'дата початку',
  days: 'днів',
  daysDot: 'д.',
  decisionMakerName: 'ім`я ОПР',
  decisionMakerPhone: 'телефон ОПР',
  default: 'за замовчуванням',
  defaultLocationBusinessHours: 'години роботи локацій за умовчанням',
  defaultPageTitle: 'CM-Service DESK',
  defaultReactionTime: 'час реакції за замовчуванням',
  defaultSolutionTime: 'час вирішення за замовчуванням',
  delete: 'видалити',
  deleteAccount: 'видалити аккаунт',
  deleteUserAccount:
    'аккаунт буде видалений, користувач більше не зможе увійти в систему.',
  description: 'опис',
  details: 'реквізити',
  distance: 'відстань',
  distanceFromService: 'віддаленість від СЦ',
  document: 'документ',
  download: 'завантажити',
  edit: 'редагувати',
  editDate: 'редагувати дату',
  employee: 'працівник',
  eqTypes: 'типи обладнання',
  equipment: 'обладнання',
  equipments: 'обладнання',
  error: 'помилка',
  errorLoadingFromServer: 'помилка під час завантаження із сервера ',
  fault: 'несправность',
  faultCode: 'код несправності',
  faultGroup: 'група несправностей',
  faultGroups: 'групи несправностей',
  faults: 'несправності',
  filter: 'фільтр',
  filters: 'фільтри',
  firstDeleteAllRelations: 'спочатку потрібно видалити всі супутні елементи',
  firstName: 'ім`я',
  forgotPassword: 'забули пароль?',
  from: 'початок',
  fromClients: 'від клієнтів',
  generalInfo: 'загальна інформація',
  globals: 'глобальні',
  gps: 'gps',
  group: 'група',
  groups: 'групи',
  hide: 'сховати',
  home: 'головна',
  hours: 'годин',
  hoursDot: 'ч.',
  identFaultGroups: 'групи виявлених несправностей',
  identFaults: 'виявлені несправності',
  idents: 'виявлені',
  instruction: 'інструкція',
  km: 'км',
  language: 'мова',
  lastName: 'прізвище',
  loading: 'завантаження',
  placement: 'розташування',
  places: 'розташування',
  location: 'локація',
  locations: 'локації',
  login: 'вхід',
  loginIncorect: 'невірний логін або пароль',
  mailingList: 'емайли для розсилки',
  managerEmail: 'email менеджера',
  managerName: 'ім`я менеджера',
  managerPhone: 'телефон менеджера',
  maximum: 'максимум',
  maxReactionTimeByContract: 'макс. час реакції за договором',
  maxSolutionTimeByContract: 'макс. час вирішення за договором',
  maxTimeOfWorkByContract: 'макс. час роботи за договором',
  maxWorkTime: 'максимальний час роботи',
  middleName: 'по батькові',
  minimum: 'мінімум',
  minutes: 'хвилин',
  minutesDot: 'хв.',
  model: 'модель',
  models: 'моделі',
  name: 'ім`я',
  new: 'новий',
  nextTry: 'наступна спроба',
  no: 'ні',
  noData: 'немає даних',
  notActive: 'не активний',
  notFound: 'не знайдено',
  notFoundDescription: 'можливо, ресурс було переміщено або видалено',
  notReceiveCode: 'не отримали код?',
  notification: 'повідомлення',
  notifications: 'повідомлення',
  notificationTimeTaked: 'час повідомлення до призначен',
  notificationTimeReacted: 'час повідомлення до реакції',
  notificationTimeResolved: 'час повідомлення до рішення',
  now: 'зараз',
  number: 'номер',
  object: 'об`єкт',
  objects: 'об`єкти',
  onlyTheir: 'тільки свої',
  opened: 'відкрито',
  order: 'наряд-замовлення',
  orderBusinessHours: 'години прийому наряд-замовлень',
  orderCreatorDetails: 'подробиці наряд-замовлення',
  orderCreatorName: 'ім`я творця наряд-замовлення',
  orderCreatorPhone: 'телефон творця наряд-замовлення',
  orderCreatorType: 'тип творця наряд-замовлення',
  orderEditRules: 'правила редагування наряд-замовлення',
  orders: 'наряд-замовлення',
  orderStatus: 'статус наряд-замовлення',
  orderStatuses: 'стани наряд-замовлень',
  orderStatusHistory: 'історія статусів наряд-замовлень',
  orderType: 'типи наряд-замовлення',
  orderTypes: 'типи наряд-замовлень',
  otherFault: 'інша причина виклику',
  otherM: 'інший',
  otherW: 'інша',
  owner: 'власник',
  owners: 'власники',
  part: 'запчастина',
  partGroup: 'група запчастин',
  partGroups: 'групи запчастин',
  parts: 'запчастини',
  password: 'пароль',
  permissions: 'дозволи',
  phone: 'телефон',
  phones: 'телефони',
  pressure: 'тиск',
  range: 'діапазон',
  reaction: 'реакція',
  reactionDate: 'дата початку робіт',
  reactionTime: 'час реакції',
  reactionTimer: 'таймер реакції',
  region: 'область',
  regions: 'областї',
  removeSelected: 'видалити вибране',
  resend: 'надіслати ще раз',
  reset: 'скинути',
  resetSort: 'скинути сортування',
  retry: 'повторити',
  role: 'роль',
  roles: 'ролі',
  save: 'зберегти',
  savePassword: 'зберегти пароль',
  salesByBeverages: 'продажі напоїв',
  salesByWeekDays: 'продажі по днях тижня',
  salesByHours: 'продажі по годинах',
  search: 'пошук',
  selectAll: 'вибрати все',
  sendCode: 'надіслати код',
  serialNumber: 'серійний номер',
  serverError: 'помилка сервера',
  serverValidationError: 'помилка перевірки на сервері',
  serviceCenter: 'сервісний центр',
  settings: 'налаштування',
  scanQrForServiceRequest:
    'Відскануйте цей QR для створення наряд-замовлення на ремонт',
  shortName: 'ім`я',
  show: 'показати',
  showMore: 'показати ще',
  sla: 'угода про рівень обслуговування',
  solution: 'рішення',
  solutionDate: 'дата закінчення робіт',
  solutionTime: 'час вирішення',
  solutionTimer: 'таймер рішення',
  sort: 'порядок',
  staff: 'співробітник',
  staffs: 'співробітники',
  state: 'стан',
  status: 'стан',
  statuses: 'стани',
  stopNotifications: 'зупинити повідомлення',
  stopReactionTimer: 'зупинити таймер реакції',
  stopSolutionTimer: 'зупинити таймер рішення',
  superAdmin: 'супер-адмін',
  tableWidth: 'ширина таблиці',
  telegram: 'телеграм',
  telemetry: 'телеметрія',
  time: 'час',
  timeByFaultType: 'час за типом несправності',
  timeDefault: 'час за замовчуванням',
  timeOfWork: 'час роботи',
  title: 'назва',
  to: 'закінчення',
  toRecoverPassword:
    'Щоб відновити свій пароль, введіть свій номер телефону, і ми надішлемо вам SMS із кодом відновлення',
  total: 'всього',
  transitionFrom: 'перехід з',
  transitionTo: 'перехід на',
  type: 'тип',
  types: 'типи',
  unselectAll: 'скасувати вибір усіх',
  update: 'редагувати',
  user: 'користувач',
  userName: 'ім`я користувача',
  users: 'користувачі',
  verifyCode: 'перевірити код',
  video: 'відео',
  voltage: 'напруга',
  waitLoadingFromServer:
    'будь ласка, дочекайтеся закінчення завантаження із сервера',
  withoutAccount: 'без аккаунта',
  work: 'работа',
  workTime: 'час роботи',
  workGroups: 'групи робіт',
  works: 'работи',
  yes: 'так',
  zone: 'зона',
  zones: 'зони',
  validation: {
    alpha: 'має містити тільки літери',
    alreadyTaked: 'вже зайнято',
    alreadyStaff: 'інший виконавець вже є в списку, перезавантажте сторінку',
    email: 'повинна бути дійсна електронна адреса',
    emails: 'вводити електронні листи через кому',
    ipVsPort: 'має бути дійсним ip та портом: xxx.xxx.xxx.xxx:xxxxx',
    isChangedValue: 'виберіть нове значення',
    length: 'має мати {n} символ | має містити {n} символів',
    maxLength: 'макс. {n} символ | макс. {n} символів',
    minLength: 'мін. {n} символ | мін. {n} символів',
    numeric: 'має бути число',
    password: {
      hasLowerCase: 'малу літеру',
      hasNumbers: 'цифру',
      hasSymbol: 'символ',
      hasUpperCase: 'велику літеру',
      minLength: 'мін. 8 символів',
      mustContain: 'пароль повинен містити:'
    },
    passwordMismatch: 'паролі не співпадають',
    phone: 'має бути дійсний номер телефону: +XXXXXXXXXXX',
    required: 'не може бути порожнім',
    sameAs: 'не збігається з: {n}',
    url: 'має бути дійсним URL-посиланням'
  },
  weekDays: {
    sunday: 'неділя',
    monday: 'понеділок',
    tuesday: 'вівторок',
    wednesday: 'середа',
    thursday: 'четвер',
    friday: 'п`ятниця',
    saturday: 'субота',
    holiday: 'вихідний',
    sun: 'нд',
    mon: 'пн',
    tue: 'вт',
    wed: 'ср',
    thu: 'чт',
    fri: 'пт',
    sat: 'сб'
  },
  $vuetify: uk
}
