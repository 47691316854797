import can from '@/router/middleware/can'

import auth from '../../middleware/auth'

export default [
  {
    path: '/equipments',
    name: 'Equipments',
    component: () =>
      import(
        /* webpackChunkName: "equipments" */ '@/pages/equipments/EquipmentIndex'
      ),
    meta: {
      middleware: [can('isEquipmentShow'), auth],
      title: 'equipments',
      breadCrumb: [{ text: 'equipments' }]
    }
  },
  {
    path: '/equipment/:equipmentId',
    name: 'EquipmentShow',
    component: () =>
      import(
        /* webpackChunkName: "equipmentShow" */ '@/pages/equipments/EquipmentShow'
      ),
    props: true,
    meta: {
      middleware: [can('isEquipmentShow'), auth],
      title: 'equipments',
      breadCrumb: [
        { text: 'equipments', to: { name: 'Equipments' } },
        { text: undefined }
      ]
    }
  }
]
