import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/administration/global',
    name: 'SettingsGlobal',
    component: () =>
      import(
        /* webpackChunkName: "SettingsGlobal" */ '@/pages/settings/administration/global/GlobalIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'globals',
      breadCrumb: [
        { text: 'settings' },
        { text: 'administration' },
        { text: 'global' }
      ]
    }
  }
]
