<template>
  <v-list dense>
    <naw-drawer-list-item
      v-if="this.currentUser && this.currentUser.role.isOrderShow"
      :title="$t('orders')"
      :icon="'mdi-archive-arrow-down'"
      :to="{ name: 'Orders' }"
    />
    <naw-drawer-list-item
      v-if="currentUser && currentUser.role.isClientShow"
      :title="$t('clients')"
      :icon="'mdi-card-account-details'"
      :to="{ name: 'Clients' }"
    />
    <naw-drawer-list-item
      v-if="currentUser && currentUser.role.isContractShow"
      :title="$t('contracts')"
      :icon="'mdi-file-document-multiple-outline'"
      :to="{ name: 'Contracts' }"
    />
    <naw-drawer-list-item
      v-if="currentUser && currentUser.role.isLocationShow"
      :title="$t('locations')"
      :icon="'mdi-map-marker-radius'"
      :to="{ name: 'Locations' }"
    />
    <naw-drawer-list-item
      v-if="currentUser && currentUser.role.isEquipmentShow"
      :title="$t('equipments')"
      :icon="'mdi-coffee-maker'"
      :to="{ name: 'Equipments' }"
    />
    <naw-drawer-list-item
      v-if="currentUser && currentUser.role.isStaffShow"
      :title="$t('staffs')"
      :icon="'mdi-account-hard-hat'"
      :to="{ name: 'Staffs' }"
    />
    <naw-drawer-list-item
      v-if="currentUser && currentUser.role.isZoneShow"
      :title="$t('zones')"
      :icon="'mdi-map-check'"
      :to="{ name: 'Zones' }"
    />
    <settings-list v-if="currentUser && currentUser.role.isSettingsShow" />
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

import SettingsList from '@/pages/settings/components/SettingsList.vue'

import NawDrawerListItem from './NawDrawerListItem.vue'
export default {
  components: { NawDrawerListItem, SettingsList },
  name: 'NawDrawerList',
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
