import equipment from './equipment'
import fault from './fault'
import global from './global'
import order from './order'
import part from './part'
import placement from './placement'
import role from './role'
import work from './work'

export default [
  ...equipment,
  ...order,
  ...fault,
  ...work,
  ...part,
  ...role,
  ...global,
  ...placement
]
