import can from '@/router/middleware/can'

import auth from '../../middleware/auth'

export default [
  {
    path: '/locations',
    name: 'Locations',
    component: () =>
      import(
        /* webpackChunkName: "locations" */ '@/pages/locations/LocationIndex'
      ),
    meta: {
      middleware: [can('isLocationShow'), auth],
      title: 'locations',
      breadCrumb: [{ text: 'locations' }]
    }
  },
  {
    path: '/location/:locationId',
    name: 'LocationShow',
    component: () =>
      import(
        /* webpackChunkName: "locationShow" */ '@/pages/locations/LocationShow'
      ),
    props: true,
    meta: {
      middleware: [can('isLocationShow'), auth],
      title: 'locations',
      breadCrumb: [
        { text: 'locations', to: { name: 'Locations' } },
        { text: undefined }
      ]
    }
  }
]
