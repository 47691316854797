// Import the CSS or use your own!
import momentDurationFormat from 'moment-duration-format'
import moment from 'moment-timezone'
import Vue from 'vue'
import { Plugin } from 'vue-fragment'
import VueSocketIO from 'vue-socket.io'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import i18n from './i18n/i18n'
import ValidationMessages from './plugins/validationMessages'
import vuetify from './plugins/vuetify'
import router from './router'
import AxiosService from './services/axios.service'
import store from './store'

AxiosService.init(router, store)
Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(ValidationMessages)
Vue.use(Plugin)

moment.tz.setDefault(process.env.VUE_APP_DEFAULT_TIME_ZONE)
momentDurationFormat(moment)
Vue.prototype.$moment = moment

Vue.use(new VueSocketIO({
  debug: process.env.VUE_APP_DEBUG,
  connection: process.env.VUE_APP_API_BASE_URL,
  vuex: {
    store,
    actionPrefix: 'SOCKET_'
  },
  options: {
    path: '/ws',
    withCredentials: true,
  }
}))

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
