import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/order/orderStatus',
    name: 'SettingsOrderStatus',
    component: () =>
      import(
        /* webpackChunkName: "SettingsOrderStatus" */ '@/pages/settings/order/status/OrderStatusIndex'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'orderStatuses',
      breadCrumb: [
        { text: 'settings' },
        { text: 'orders' },
        { text: 'statuses' }
      ]
    }
  },
  {
    path: '/settings/orders/order-types',
    name: 'SettingsOrderTypes',
    component: () =>
      import(
        /* webpackChunkName: "SettingsOrderTypes" */ '@/pages/settings/order/type/OrderTypeIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'orderTypes',
      breadCrumb: [{ text: 'settings' }, { text: 'orders' }, { text: 'types' }]
    }
  }
]
