import can from '@/router/middleware/can'

import auth from '../../../middleware/auth'

export default [
  {
    path: '/settings/faults/client-faults',
    name: 'SettingsClientFaults',
    component: () =>
      import(
        /* webpackChunkName: "SettingsClientFaults" */ '@/pages/settings/fault/clientFault/index/ClientFaultIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'clientFaults',
      breadCrumb: [
        { text: 'settings' },
        { text: 'faults' },
        { text: 'clientFaults' }
      ]
    }
  },
  {
    path: '/settings/faults/client-fault-groups',
    name: 'SettingsClientFaultGroups',
    component: () =>
      import(
        /* webpackChunkName: "SettingsClientFaultGroups" */ '@/pages/settings/fault/clientFault/group/ClientFaultGroupIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'clientFaultGroups',
      breadCrumb: [
        { text: 'settings' },
        { text: 'faults' },
        { text: 'clientFaults' },
        { text: 'groups' }
      ]
    }
  },
  {
    path: '/settings/faults/ident-faults',
    name: 'SettingsIdentFaults',
    component: () =>
      import(
        /* webpackChunkName: "SettingsIdentFaults" */ '@/pages/settings/fault/identFault/index/IdentFaultIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'identFaults',
      breadCrumb: [{ text: 'settings' }, { text: 'faults' }, { text: 'idents' }]
    }
  },
  {
    path: '/settings/faults/ident-fault-groups',
    name: 'SettingsIdentFaultGroups',
    component: () =>
      import(
        /* webpackChunkName: "SettingsIdentFaultGroups" */ '@/pages/settings/fault/identFault/group/IdentFaultGroupIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'identFaultGroups',
      breadCrumb: [
        { text: 'settings' },
        { text: 'faults' },
        { text: 'idents' },
        { text: 'groups' }
      ]
    }
  },
  {
    path: '/settings/faults/cause-faults',
    name: 'SettingsCauseFaults',
    component: () =>
      import(
        /* webpackChunkName: "SettingsCauseFaults" */ '@/pages/settings/fault/causeFault/index/CauseFaultIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'causeFaults',
      breadCrumb: [{ text: 'settings' }, { text: 'faults' }, { text: 'causes' }]
    }
  },
  {
    path: '/settings/faults/cause-fault-groups',
    name: 'SettingsCauseFaultGroups',
    component: () =>
      import(
        /* webpackChunkName: "SettingsCauseFaultGroups" */ '@/pages/settings/fault/causeFault/group/CauseFaultGroupIndex.vue'
      ),
    meta: {
      middleware: [can('isSettingsShow'), auth],
      title: 'causeFaultGroups',
      breadCrumb: [
        { text: 'settings' },
        { text: 'faults' },
        { text: 'causes' },
        { text: 'groups' }
      ]
    }
  }
]
