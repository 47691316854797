/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue'

import AxiosService from '@/services/axios.service'
import {
  destroyToken,
  getToken,
  getUser,
  saveToken
} from '@/services/jwt.service'

import { CHECK_AUTH } from '../../actions.type'
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_CURRENT_STAFF_REQUEST,
  SET_CURRENT_STAFF_VALUE,
  SET_LOADER_MODAL_VALUE,
  SET_USER
} from '../../mutations.type'

const state = {
  isAuthenticated: !!getToken(),
  user: undefined,
  request: {
    isLoading: undefined,
    isSuccess: undefined,
    isError: undefined,
    isFatalError: undefined,
    delay: undefined,
    lastRequestTime: undefined
  },
  staff: undefined
}

const getters = {
  isAuth: (state) => state.isAuthenticated,
  currentUser: (state) => state.staff,
  currentUserRequest: (state) => state.request
}

const actions = {
  async [CHECK_AUTH]({ commit, state }) {
    const token = getToken()
    if (state.isAuthenticated) {
      const user = getUser()
      if (!user) {
        commit(PURGE_AUTH)
        return
      }
      try {
        if (
          // state.request.isSuccess ||
          state.request.isLoading ||
          state.request.isError
        ) {
          return
        }
        try {
          AxiosService.setAuthorizationHeader(token)
          commit(SET_USER, user)

          const { data } = await Vue.axios.get('staff/by-email', {
            params: { email: user.email },
            'axios-retry': {
              beforeTry: () => {
                commit(SET_LOADER_MODAL_VALUE, { isError: false })
                commit(SET_CURRENT_STAFF_REQUEST, {
                  isLoading: true,
                  isError: false
                })
              },
              afterTry: ({ delay, lastRequestTime }) => {
                commit(SET_LOADER_MODAL_VALUE, { isError: true })
                commit(SET_CURRENT_STAFF_REQUEST, {
                  isLoading: false,
                  isError: true,
                  delay,
                  lastRequestTime
                })
              }
            }
          })
          commit(SET_CURRENT_STAFF_REQUEST, { isSuccess: true })
          commit(SET_CURRENT_STAFF_VALUE, data)
        } catch (e) {
          commit(SET_LOADER_MODAL_VALUE, { isFatalError: true })
          commit(SET_CURRENT_STAFF_REQUEST, {
            isFatalError: true
          })
        }
      } catch (e) {}
    }
  }
}

const mutations = {
  [SET_CURRENT_STAFF_REQUEST](state, request) {
    state.request = {
      ...request
    }
  },
  [SET_CURRENT_STAFF_VALUE](state, staff) {
    state.staff = staff
  },
  [SET_AUTH](state, token) {
    state.isAuthenticated = true
    saveToken(token)
    AxiosService.setAuthorizationHeader(token)
    const user = getUser()
    state.user = user
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = undefined
    destroyToken()
    AxiosService.setAuthorizationHeader()
  },
  [SET_USER](state, user) {
    state.user = user
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
