<template>
  <v-list-item link :to="to" class="naw-drawer">
    <v-list-item-icon>
      <v-icon v-text="icon" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title class="naw-drawer-title">{{
        title
      }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavDrawerListItem',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    to: {
      type: [String, Object],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.naw-drawer {
  &-title {
    text-transform: capitalize;
  }
}
</style>
