import can from '@/router/middleware/can'

import auth from '../../middleware/auth'

export default [
  {
    path: '/zones',
    name: 'Zones',
    component: () =>
      import(/* webpackChunkName: "zones" */ '@/pages/zones/ZoneIndex'),
    meta: {
      middleware: [can('isZoneShow'), auth],
      title: 'zones',
      breadCrumb: [{ text: 'zones' }]
    }
  },
  {
    path: '/zone/:zoneId',
    name: 'ZoneShow',
    component: () =>
      import(/* webpackChunkName: "zoneShow" */ '@/pages/zones/ZoneShow'),
    props: true,
    meta: {
      middleware: [can('isZoneShow'), auth],
      title: 'zones',
      breadCrumb: [
        { text: 'zones', to: { name: 'Zones' } },
        { text: undefined }
      ]
    }
  }
]
