import can from '@/router/middleware/can'

import auth from '../../middleware/auth'

export default [
  {
    path: '/staffs',
    name: 'Staffs',
    component: () =>
      import(/* webpackChunkName: "staffs" */ '@/pages/staffs/StaffIndex'),
    meta: {
      middleware: [can('isStaffShow'), auth],
      title: 'staffs',
      breadCrumb: [{ text: 'staffs' }]
    }
  },
  {
    path: '/staff/:staffId',
    name: 'StaffShow',
    component: () =>
      import(/* webpackChunkName: "staffShow" */ '@/pages/staffs/StaffShow'),
    props: true,
    meta: {
      middleware: [can('isStaffShow'), auth],
      title: 'staffs',
      breadCrumb: [
        { text: 'staffs', to: { name: 'Staffs' } },
        { text: undefined }
      ]
    }
  }
]
